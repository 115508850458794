module.exports = new Promise(resolve => {
    // This part depends on how you plan on hosting and versioning your federated modules
    const remoteUrlWithVersion = 'VUE_APP_RUNTIME_DX_URL/remoteEntry.js'

    const script = document.createElement('script')
    script.src = remoteUrlWithVersion
    script.onload = () => {
      const module = {
        get: (request) => window.user.get(request),
        init: (arg) => {
          try {
            return window.user.init(arg);
          } catch (e) {
            console.log("Problem loading user", E);
          }
        },
      };
      resolve(module);
    };
    script.onerror = () => {
      const module = {
        get: () => () => {},
        init: () => () => {},
      };
      resolve(module);
    };
    // inject this script with the src set to the versioned remoteEntry.js
    if(window.configs.VUE_APP_DISABLE_DX === "false") {
      document.head.appendChild(script);
    } else {
      const module = {
        get: () => () => {},
        init: () => () => {},
      };
      resolve(module);
    }
  })
  ;